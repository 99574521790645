import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "claim-flows-and-states",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#claim-flows-and-states",
        "aria-label": "claim flows and states permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claim flows and states`}</h1>
    <p>{`Once successfully submitted, a claim will be categorised into one of the following status:`}<br /></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Status`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Highlight mdxType="Highlight">{`Outstanding`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comcare has responded  with `}<strong parentName="td">{`Under Review`}</strong>{`. The Tyro Health  transaction will be in this state until Comcare has  reviewed it and responded later with Accepted, Partially Accepted, or Rejected.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Highlight mdxType="Highlight">{`Approved`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One or more claim items accepted and approved by Comcare. Settlement not yet issued to provider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Highlight mdxType="Highlight">{`Completed`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An Approved claim where payment has been issued by Tyro Heath  to the provider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Highlight mdxType="Highlight">{`Declined`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comcare  has responded with Rejected. Tyro Health  will not payout the claim`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Highlight mdxType="Highlight">{`Cancelled`}</Highlight></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tyro health have initiated a cancellation request on the claim and Comcare  have  responded with a successful cancellation`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`If multiple service items are claimed, each will have a unique item level status. Overall invoice status will change only where a final result has been obtained for each claim item. An invoice with at least one rejected claim item will be marked as declined.`}</p>
    <p>{`Some claims will be processed instantly by Comcare using their straight-through adjudication method. Those claims will return an approved or declined status within 30 seconds and the SDK will return that status via a callback. `}<br /></p>
    <p>{`However, partners will need to accommodate claims which undergo manual review and status updates hours to days post submission.  For each claim, a partner can nominate one or more webhooks which fire depending on the claim status. Each successfully submitted claim will also return, via SDK callback, a Tyro Health unique transaction Id which can be used to periodically poll for current status. `}<br /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      